import {Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import React from "react";
import Drawer from "@mui/material/Drawer";

import TextBody from "./modules/textBody";
import TextTitle from "./modules/textTitle";
import Image from "./modules/image";
import Music from "./modules/music";
import ImageV from "./modules/imageV";
import ImageSmall from "./modules/imageSmall";
import ImageGrid from "./modules/imageGrid";
import PreviewCard from "./modules/previewCard";
import MenuButton from "./modules/menuButton";
import MenuDrawer from "./modules/menuDrawer";

const Iceland = ({ isPreview }) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = (value) => () => {
        setOpen(value);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    let titles=[
        'Preparations',
        'Day 1 Reykjavík',
        'Day 2 The Golden Circle',
        'Day 3 Snow storm',
        'Day 4 Diamond Beach and Blue Ice Cave',
        'Day 5 Further East',
        'Day 6 Stuck in Egilsstaðir',
        'Day 7 Hit the Road Again',
        'Day 8 Snaefellsnes Peninsula',
        'Day 9 Lava Cave Tour and Barnafoss',
        'After Iceland'
    ];
    const refs = React.useRef(Array.from({ length: titles.length }, () => React.createRef()));

    const folderPath="iceland";

    return (
        <Box>
            {isPreview &&
                <PreviewCard path={folderPath}
                             title={"Iceland Forever"}
                             date={"November 6, 2024"}
                             isMobile={isMobile}>
                    My favorite place in this world
                </PreviewCard>
            }
            {!isPreview &&
                <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
                    <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>Iceland Forever</Typography>
                    <Typography color="textSecondary" className='blogDate' gutterBottom>
                        November 6, 2024
                    </Typography>
                    <Box className='bolgContent'>
                        <Music src={"https://open.spotify.com/embed/track/4VZ6IkyswWRT95vdR84fw5?utm_source=generator"}
                               title={"every word by Animal Sun"}/>
                        <TextBody>
                            This trip was back in December 2022. I might write about some of my other past trips if
                            I feel like it. Why am I starting with Iceland? I think the reason is pretty obvious.
                        </TextBody>
                        <TextBody>
                            I had been dreaming of visiting Iceland since I was about 13. At first, I only had the
                            simplest thought of seeing the northern lights, not expecting anything else. But the
                            beauty of Iceland is so much beyond my imagination.
                        </TextBody>
                        <TextBody>
                            DISCLAIMER!!! I love recommending Iceland to my friends, but if you don't like natural
                            scenery, are only there for the photos, can't stand the cold, hate long hours on road
                            trips traveling from one spot to another, love city nightlife, or are looking for
                            various activities during your trip—please don’t come to Iceland. This is not your
                            place. Social media has beautified this place too much. You think you will enjoy the
                            loneliest place in the world, you won't. Loneliest isn't just about the scene here, but
                            also lifestyle too. Don't complain in front of me cause you are the one who didn't do
                            enough research before coming to Iceland. You’re the one who made the wrong choice and
                            got blinded by social media. Iceland has always been like this, and there's nothing
                            wrong with it. Don't even try to argue with me on this, I LOVE ICELAND!
                        </TextBody>
                        <TextBody>
                            Fun story: my final was due during my trip, and I really didn’t want to spare any time
                            for it while traveling. So the night before I left, I was at my friend's place, catching
                            up with everyone and busy working on my final while others were playing Mario Party.
                        </TextBody>
                        <TextBody>
                            Ok now we are good to talk about this charming place.
                        </TextBody>
                        <TextTitle ref={refs.current[0]}>
                            {titles[0]}
                        </TextTitle>
                        <TextBody>
                            <b>Weather</b>. Iceland can be very snowy during winter of course, and December is one
                            of the worst months. That’s one of the reasons why winter is considered the off-season
                            in Iceland. Also, because of its high latitude, Iceland only gets around 4 hours of
                            daylight each day. I'm a fan of the dark, I'm thrilled about this. I understand a lot of
                            people find this amount of darkness depressing.
                        </TextBody>
                        <TextBody>
                            <b>Clothes</b>. Expecting the weather to be extreme. Even though I've lived in northern
                            China for many years and am used to snowy conditions, I assumed Iceland's weather would
                            be tougher. I wore waterproof and windproof jackets, pants and shoes, and layered up my
                            clothes, just as recommended in some "Must Knows Before You Come to Iceland" articles.
                            These preparations proved to be very effective. I stayed dry and warm the whole time,
                            even when we were hiking in a snowstorm.
                        </TextBody>
                        <TextBody>
                            <b>Cameras</b>. I knew my phone was ancient and would get stuck and die very quickly in
                            cold weather. Also I didn't own a fancy digital camera. A friend told me that GoPro has
                            an endurance battery that can handle freezing temperatures. I saw no reason why not. I
                            bought the latest GoPro model at that time. I knew GoPro was great for sports videos,
                            but it ended up proving its worth in so many other ways. I used it to capture photos of
                            the northern lights and the Milky Way, took it diving, and just everywhere. It became my
                            best friend, recording lots of beautiful moments for me. For sure, it's not as good as a
                            professional camera, but considering the portability, durability, and how well the
                            videos turn out, I’d go with GoPro without a doubt.
                        </TextBody>
                        <TextBody>
                            <b>How to Travel</b>. I hadn’t started driving back then, and I knew how scary and
                            dangerous it could be for a new driver to navigate in the snow. So I chose to follow a
                            tour. I don’t usually like doing tours, but I think it was my best choice. I wanted to
                            go around the whole island of Iceland, and there was just one 8-day tour available on <a
                            href="https://guidetoiceland.is" target="_blank" rel="noreferrer">Guide to Iceland</a>.
                            So it was it! For extra items, I bought VÖK Bath and Víðgelmir Lava Cave Tour. By the
                            way, all the tips and info I got were from this website. There are many travel agencies
                            listed there, and I went with Troll Expeditions. Again, they were my only option. I did
                            see that some people had unpleasant experiences with Troll or other agencies, so please
                            make sure to do your research, go through the policies carefully and solve all your
                            questions with the online agent before booking your trip.
                        </TextBody>
                        <TextBody>
                            As for the northern lights, I was like most people, not knowing much about how they
                            truly look before I went. I'll talk more about it later.
                        </TextBody>
                        <TextTitle ref={refs.current[1]}>
                            {titles[1]}
                        </TextTitle>
                        <TextBody>
                            At that time I was obsessed with a not-so-famous band called Animal Sun. They have a
                            song called every word, and that line "your whisper's louder than the arctic breeze"
                            kept haunting me.
                        </TextBody>
                        <TextBody>
                            After so long, I still can't spell the word Reykjavík correctly.
                        </TextBody>
                        <TextBody>
                            Since the tour started early in the morning from Reykjavík, I arrived a day early just
                            in case of any unexpected conditions. I got there in the morning and spent the whole day
                            exploring downtown Reykjavík. Sunrise was until after 11am, most stores open at least
                            after 10am.
                        </TextBody>
                        <ImageSmall path={folderPath} name={1}>
                            Freezing weather goes best with hot chocolate
                        </ImageSmall>
                        <TextBody>
                            There was a boba place right across the street from my hotel, which was a great comfort. I didn't go though. I was busy enjoying my hot chocolate.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[2, 3, 4]} ratio={[4, 4, 4]}>
                            Street views in downtown Reykjavík
                        </ImageGrid>
                        <TextBody>
                            Downtown Reykjavík isn't very big, everywhere is within walking distance.
                        </TextBody>
                        <TextBody>
                            Oh yes forget to mention, I bought the SIM card after arriving at the airport and then took the shuttle bus to downtown Reykjavík. There are about 20 numbered stops where the shuttle can drop you off if I remember correctly. Taking a taxi is an option, but you need to call to book one. I assume it’s quite pricey, and I did see others confirming my assumption. Public transportation is available in town.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[5, 6, 7]} ratio={[6, 3, 3]}>
                            Hallgrimskirkja, the church
                        </ImageGrid>
                        <TextBody>
                            Had lunch at a local place. I noticed two songs played by the bartender, one was Uptown Girl and the other was Don't You Want Me. I knew Don't You Want Me cause it was the main storyline in one of the Black Mirror episodes. When checking out, I told the bartender great song choices, and he said "Oh 80s is the best".
                        </TextBody>
                        <ImageSmall path={folderPath} name={8}>
                            A drink called the northern lights
                        </ImageSmall>
                        <TextBody>
                            I love the song Don't You Want Me. And I love how it was performed in the Black Mirror. After lunch, I went straight to the record store next door, asking if they had the album. They had it! I bought the album together with the original soundtrack of the movie Bohemian Rhapsody. And that store happens to be the largest record store in Reykjavík.
                        </TextBody>
                        <ImageSmall path={folderPath} name={9}>
                            Vinyl of the album which has Don't You Want Me
                        </ImageSmall>
                        <TextBody>
                            Downtown exploration continued.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[10, 11, 12]} ratio={[4, 4, 4]}>
                            Other downtown views
                        </ImageGrid>
                        <TextBody>
                            Reykjavík really wasn't the coldest part of the trip, warmest probably. After a whole day of walking, I went straight to the store and bought a scarf. Why didn’t I bring one? Cause I didn't have one. This scarf was a real lifesaver.
                        </TextBody>
                        <ImageSmall path={folderPath} name={13}>
                            Icelandic wool scarf. Warm!
                        </ImageSmall>
                        <TextBody>
                            Ending this day with mailbox only to Santa.
                        </TextBody>
                        <ImageSmall path={folderPath} name={14}>
                            Letters for Santa
                        </ImageSmall>
                        <TextTitle ref={refs.current[2]}>
                            {titles[2]}
                        </TextTitle>
                        <TextBody>
                            The tour started today.
                        </TextBody>
                        <TextBody>
                            My tour guide who was also the driver was picking me up. Interestingly, they didn’t give me any kind of contact information before the pickup. I was worried. What if they never showed up? After several emails going back and forth, they gave me a number, though it wasn’t the driver’s. But at least I got something. He showed up on time. Cheers! Later I realized this is very Icelandic style.
                        </TextBody>
                        <TextBody>
                            My tour guide's name is Jon. He’s a professional photographer with impressive achievements. I also want to introduce some of the friends I met in the group. They all came alone, and I ended up hanging out with them the whole time. And they are going to be the main characters in my story.
                        </TextBody>
                        <TextBody>
                            Ryan is from Hong Kong, it was his first time traveling abroad. He was budget traveling, carefully counting his money for every meal, but he was generous when it came to trying everything new in Iceland to make the most of the trip. He had never been to northern China before and had never seen snow.
                        </TextBody>
                        <TextBody>
                            Kathryn is from Minnesota. She’s very outgoing and talked to everyone on our bus.
                        </TextBody>
                        <TextBody>
                            Grace is from Australia. She saved up for 4 years during college for this huge 3-month graduation trip around Europe. The pandemic messed up all her previous travel plans.
                        </TextBody>
                        <TextBody>
                            I’m terrible with names, so if I got someone's name wrong, I’M SORRY!
                        </TextBody>
                        <TextBody>
                            There was another couple in our group from the US who lived in their RVs. They booked this trip a long time ago because it was way cheaper to book early. But a few months before the trip, the lady found out she was pregnant. There’s a rule that women 28 weeks pregnant aren’t allowed on a plane. Both the tour and the flight tickets were non-refundable. Luckily, she was 27 weeks when the trip took place. They checked with her doctor and decided to go on the trip anyway. I’m sharing this cause I thought it was interesting.
                        </TextBody>
                        <TextBody>
                            In fact, I could tell the story of everyone in the group because we were all hanging out together. We spent a lot of time on the road, so the only thing we could do other than sleep was talk.
                        </TextBody>
                        <TextBody>
                            The Golden Circle is the most popular destination for tourists in Iceland. It’s a must-see. I’ll let the photos speak.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[15, 16, 17]} ratio={[3, 5, 4]}>
                            Þingvellir National Park
                        </ImageGrid>
                        <ImageSmall path={folderPath} name={18}>
                            Öxarárfoss Waterfall
                        </ImageSmall>
                        <ImageGrid path={folderPath} name={[19, 20, 21, 22]} ratio={[3, 3, 3, 3]}>
                            Geysir
                        </ImageGrid>
                        <ImageSmall path={folderPath} name={23}>
                            Gullfoss Waterfall
                        </ImageSmall>
                        <TextBody>
                            As you can see, waterfalls are one of the main attractions in Iceland. We were getting complaints already when we got to Seljalandsfoss. They were wondering why so many waterfalls, and why only waterfalls.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[24, 25, 26]} ratio={[4, 4, 4]}>
                            Seljalandsfoss Waterfall
                        </ImageGrid>
                        <TextBody>
                            We went back to Hvolsvöllur to stay for the night. It was a really small town. Other than our hotel, we only found one gift shop, one gas station, one grocery store, and one decent restaurant open. Almost all of us went to that restaurant because we needed some good food after such a long day. I bought stamps from the gift shop and wrote this postcard. I sent it to my address.
                        </TextBody>
                        <ImageSmall path={folderPath} name={27}>
                            The puffin postcard I wrote to myself. It's on my fridge now
                        </ImageSmall>
                        <TextBody>
                            It was pretty cloudy that night. Though you can see the northern lights from anywhere in Iceland, we were still too far south to catch a good one. So no luck that day.
                        </TextBody>
                        <TextTitle ref={refs.current[3]}>
                            {titles[3]}
                        </TextTitle>
                        <TextBody>
                            The weather suddenly worsened. It was snowstorming. The weather was so bad that Jon said if our trip had been scheduled to start that day, the entire tour would have been canceled.
                        </TextBody>
                        <ImageSmall path={folderPath} name={28}>
                            On our way in the storm
                        </ImageSmall>
                        <TextBody>
                            Surprisingly, it didn’t affect our plans at all. We were still going to all those places and doing all of those things, except in a storm.
                        </TextBody>
                        <TextBody>
                            First stop, another waterfall! It was still dark, snowing crazily. As we got closer to the waterfall, cold water mixed with snowflakes slammed into my face. I FaceTimed my best friend and my parents, though my phone was barely working. My dad told me to get closer to the waterfall. I replied, "Freezing cold water is slamming my face!" He said, "Well, put up with it."
                        </TextBody>
                        <ImageGrid path={folderPath} name={[29, 30]} ratio={[6, 6]}>
                            Skógafoss Waterfall
                        </ImageGrid>
                        <TextBody>
                            Fresh snow started to accumulate. Ryan was running around with pure excitement.
                        </TextBody>
                        <TextBody>
                            Oh by the way, don’t get too close to the water. You might think you’re walking on the shore, but you’re actually walking on thin ice. I almost stepped into the river. Super close call!
                        </TextBody>
                        <TextBody>
                            Next stop is one of the places I looked forward to most, the Black Sand Beach. Again, it was storming, so it wasn't entirely Black Sand Beach. To me it was more like an Oreo Sand Beach.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[31, 32, 33]} ratio={[4, 5, 3]}>
                            Black Sand Beach
                        </ImageGrid>
                        <TextBody>
                            And here's the most decent photo I took that day.
                        </TextBody>
                        <ImageV path={folderPath} name={34}>
                            Me
                        </ImageV>
                        <TextBody>
                            I rushed into the diner nearby after I couldn't see anything through my foggy wet glasses and had taken enough photos.
                        </TextBody>
                        <ImageSmall path={folderPath} name={38}>
                            Tip jar at the diner
                        </ImageSmall>
                        <TextBody>
                            When I first stepped in, it was still pretty clear outside, but after I ordered a cup of coffee and sat by the window, the storm started raging. Fascinating isn't it? After my coffee, the sky cleared up and nothing seemed to ever happen.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[35, 36, 37]} ratio={[4, 4, 4]}>
                            Conditions outside the window at different times
                        </ImageGrid>
                        <TextBody>
                            Passed by this guy by the road. Such a sad scene.
                        </TextBody>
                        <Image path={folderPath} name={39}>
                            Sad story in the snow, look at his tire
                        </Image>
                        <TextBody>
                            Our next item on the list was glacier hiking. We were so surprised it didn't get canceled. Jon told us he called to check, and they said there was no reason to cancel the hike. Interesting, very interesting. Got all my gear, checked with my friends for each other to make sure we tucked our scarves well. It's gonna be rough out there.
                        </TextBody>
                        <ImageV path={folderPath} name={40}>
                            The gear. They are actually more necessary during summer but we put them on anyway
                        </ImageV>
                        <TextBody>
                            You don't necessarily need a guide to do this hike, you can totally do it yourself as long as you know the way, which I think is pretty tough under this condition with everywhere covered in pure white.
                        </TextBody>
                        <TextBody>
                            We have two guides, one is French at the front leading the way, and the other is at the back taking care of all of us. I'm most certain they told us their names. I'm sorry, I can't remember. They told me you only need to go through four days of training to be the guide here. I want that job.
                        </TextBody>
                        <Image path={folderPath} name={41}>
                            Our French guide. We were already on the glacier at that point. The river behind him should be flowing during summer times
                        </Image>
                        <TextBody>
                            we were their only guests that day, cause other groups either couldn't make it here, or their tours were canceled entirely.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[42, 43, 44]} ratio={[4, 3, 5]}>
                            Sólheimajökull glacier in snow storm
                        </ImageGrid>
                        <TextBody>
                            If it were springtime, you could even go down into this huge crevice.
                        </TextBody>
                        <ImageV path={folderPath} name={45}>
                            A huge crevice we dare not to get to close
                        </ImageV>
                        <TextBody>
                            You thought today’s story ended here? Not even close.
                        </TextBody>
                        <TextBody>
                            Due to the polar night, the sunset is around 3:30 pm. When we finished the hike, it was still bright. We thought we'd make it back to the hotel early and get some rest. Remember, we were the only guests that day. Other than our bus, there were only the guides' cars and a few others who helped us get ready earlier parked there.
                        </TextBody>
                        <TextBody>
                            Shortly after we drove off from the glacier, before even hitting the main road, we got stuck in the snow. The roads had been plowed before, but the storm had been raging the whole time. The people who would have plowed the snow had been with us all the time. Our guides left a little bit later than us. When they found out we were stuck, they stopped, pulled out shovels, and immediately jumped in to help. At first, no one thought it was a huge problem. They worked a bit, we tried to move the car, it didn’t work. They continued to work, and we kept trying to move the car.
                        </TextBody>
                        <ImageV path={folderPath} name={46}>
                            Our guides were working hard to save us
                        </ImageV>
                        <TextBody>
                            When it got dark and they stopped shoveling and started discussing, people started to get off the bus to check what was happening. That’s when we realized it was serious. They had tried everything they could. We would have loved to help, but there weren’t any spare shovels. The ones they had were the ones they took everywhere just in case.
                        </TextBody>
                        <TextBody>
                            Most of us got off the bus and started trying to push it out. I joined them right after I took this photo.
                        </TextBody>
                        <Image path={folderPath} name={47}>
                            Trying to push the car
                        </Image>
                        <TextBody>
                            It worked. That was the second day of our tour, so we weren’t very familiar with each other yet. But after this small accident, we all started working together, talking, and cheering when we finally made it out. It was amazing.
                        </TextBody>
                        <TextBody>
                            We finally arrived at our hotel near Vik. That hotel was even more rural. That hotel was the only building there. We had dinner together, sitting together talking about what happened during the day.
                        </TextBody>
                        <ImageSmall path={folderPath} name={48}>
                            Outside the hotel. Care for a drink?
                        </ImageSmall>
                        <TextBody>
                            I gave the postcards I had written to myself and my friends to the hotel reception, as they told me that someone picks up the mail every morning. I thought I’d have to bring them back to Reykjavík to send them.
                        </TextBody>
                        <TextBody>
                            Snow storms, so another night with no luck of northern lights.
                        </TextBody>
                        <TextTitle ref={refs.current[4]}>
                            {titles[4]}
                        </TextTitle>
                        <TextBody>
                            Had breakfast at the hotel. There was a bottle on the table labeled "Fish Oil" with a tag saying "True Vikings drink this every day." What kind of sick people are they.
                        </TextBody>
                        <TextBody>
                            We were out of the storm.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[49, 50, 51]} ratio={[4, 4, 4]}>
                            At a random gas station
                        </ImageGrid>
                        <TextBody>
                            Nothing much to say about Diamond Beach, just astonishing.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[52, 53, 54]} ratio={[4, 4, 4]}>
                            Diamond Beach
                        </ImageGrid>
                        <TextBody>
                            Next stop: the Blue Ice Cave. We parked at Jökulsárlón Glacier Lagoon for two reasons. First, it was the only place to eat before ice caving and reaching our hotel later that night. Second, it was the starting point for our ice caving adventure.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[55, 56, 57]} ratio={[4, 5, 3]}>
                            Viewpoint over Jökulsárlón
                        </ImageGrid>
                        <TextBody>
                            We took this huge badass car to the ice cave starting point since we needed to go off-road. Then we had to hike on the glacier for a while to reach the caves. The pregnant lady joined us too, though there were some concerns about her slipping and falling, she never missed a thing. She was at the very front of our group, with the guides paying extra attention to her, and we all followed her pace.
                        </TextBody>
                        <Image path={folderPath} name={58}>
                            The car taking us off-road
                        </Image>
                        <TextBody>
                            Ice caves are moving and changing constantly due to wind and weather. They collapse and new ones are formed every day.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[59, 60, 61]} ratio={[4, 4, 4]}>
                            Inside the ice cave, no filter at all
                        </ImageGrid>
                        <ImageGrid path={folderPath} name={[68, 67]} ratio={[6, 6]}>
                            Another bigger ice cave that we didn't have to crawl in
                        </ImageGrid>
                        <TextBody>
                            One of our guides found it. He insisted it was a huge diamond and that I took a picture with it.
                        </TextBody>
                        <ImageSmall path={folderPath} name={62}>
                            Iceland nature-made diamond, extremely rare
                        </ImageSmall>
                        <ImageSmall path={folderPath} name={63}>
                            What I believed to be a troll's footprint
                        </ImageSmall>
                        <TextBody>
                            If you signed up for a typical 3-day trip which covers the main spots like most tourists do, this is where your trip would end. Instead of heading further east, your tour guide would drive you back to Reykjavík on this day.
                        </TextBody>
                        <TextBody>
                            Stayed the night in Höfn. That night happened to be the final of the 2022 FIFA World Cup, Argentina vs. France. The TV in our hotel’s common area was live-streaming the game. I don’t know much about football and don’t usually watch the games, but I didn’t want to miss this one. People started gathering, watching together. We were all tourists from different corners of the world, and we all wanted Argentina to win—and they did. How fascinating, on a random day in December 2022, you were in a small hotel in Iceland, watching World Cup with total strangers, all cheering for the same team. I dared not even dream about it.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[64, 65, 66]} ratio={[4, 4, 4]}>
                            The final
                        </ImageGrid>
                        <TextBody>
                            One of the guys there mentioned his son roots for France. After Argentina won, he said "Oh my son’s not going to be happy about this," followed by laughs.
                        </TextBody>
                        <TextBody>
                            After everything settled, Ryan, Grace, Kathryn and I went out for dinner. Ryan ordered a reindeer burger, but it wasn't very satisfying. I always have a strange feeling seeing reindeer on the menu. If we're eating Santa's means of transportation, how is he going to travel?
                        </TextBody>
                        <TextBody>
                            That was the first clear night of our trip. The chances of seeing the northern lights were very low, with the aurora prediction app showing only a 10% chance. But Ryan and I decided to go for it anyway.
                        </TextBody>
                        <TextBody>
                            To see the northern lights, you need to be away from any light pollution and face the northern sky, looking for a faint grey-white strip. If the aurora isn’t strong, that’s what it will look like.
                        </TextBody>
                        <TextBody>
                            Höfn is a larger town than the ones we stayed in, with lots of residents. We tried to stay away from the houses as much as possible cause they all had lights on. After walking for about 30 minutes in the cold wind, we stopped in front of a small hotel, thinking it was a good spot. It provided a place to set up the camera and walls to block the wind. But it was still freezing. We left the cameras outside and hid inside the hotel for a short while. There was no one at the front desk. We also hid in a church nearby from the strong wind for a while.
                        </TextBody>
                        <ImageSmall path={folderPath} name={69}>
                            The northern light we saw that night
                        </ImageSmall>
                        <TextBody>
                            The northern lights were very faint that night, all we could see were white stripes in the sky. But we were still thrilled. It was our first hunt, we made the effort and did see something.
                        </TextBody>
                        <TextTitle ref={refs.current[5]}>
                            {titles[5]}
                        </TextTitle>
                        <TextBody>
                            The wind was howling as we drove further east. Passing through the East Fjords, the wind made our bus shake. By the side of the road was steep cliffs and rushing rivers. It was quite scary to be honest.
                        </TextBody>
                        <TextBody>
                            We made a stop at Djúpivogur. Saw Eggin í Gleðivík and Djúpivogur Mural, did a short hike up the hill, had lunch and that was it. Aiming for Egilsstaðir for the night, which is the largest town in east Iceland.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[70, 71, 72]} ratio={[4, 4, 4]}>
                            At Djúpivogur. Red lights indicate you shouldn't go any further
                        </ImageGrid>
                        <TextBody>
                            The main activity that day was horseback riding, which I didn’t sign up for, and a visit to VÖK Baths in Egilsstaðir. It started snowing when we arrived. I spent some time exploring the town while some others went horseback riding. I bought this silly goose cause it reminded me of a meme.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[73, 74]} ratio={[6, 6]}>
                            The goose and the meme
                        </ImageGrid>
                        <ImageV path={folderPath} name={76}>
                            In Egilsstaðir
                        </ImageV>
                        <TextBody>
                            Their horseback riding experience wasn’t too peaceful, riding in the middle of a snowstorm. Jon said he had never experienced anything like it before, the people at the barn said that was a first. They finished the ride in a rush and we all gathered afterward, ready for the bath. Many people love going to the Blue Lagoon and Sky Lagoon because they’re close to Reykjavík and you can get nice pictures. But after my experience, I’d definitely prefer a local spot.
                        </TextBody>
                        <Image path={folderPath} name={75}>
                            Our bath in the snowstorm
                        </Image>
                        <TextBody>
                            Jon was updating us on the latest weather conditions. We were expecting a large storm that night, and it was almost certain that we would get stuck in the town the next day. It would be too dangerous for workers to plow the roads. Our trip plans would be affected greatly or worse, our Christmas plans and all the flight schedules would be messed up. We had no idea how bad the storm would be or where it would leave us. But for now, we decided to enjoy the bath and focus on the drinks in our hands. That felt so Icelandic.
                        </TextBody>
                        <TextBody>
                            We had tea of our choice provided by the place after the bath.
                        </TextBody>
                        <TextBody>
                            Later I knew that the storm was so severe that all flights in and out of Iceland were canceled for that day and the following one.
                        </TextBody>
                        <TextTitle ref={refs.current[6]}>
                            {titles[6]}
                        </TextTitle>
                        <TextBody>
                            No surprises, the storm continued, and we were stuck for the entire day.
                        </TextBody>
                        <TextBody>
                            Our original plan for that day was to drive north, visit Lake Mývatn and Dimmuborgir, and spend the night in Akureyri. But now, with us being stuck, the first thing that came to mind was going outside and building a snowman. I taught Ryan how to roll a snowball and build a snowman. I didn't realize it was something you needed to learn until I met Ryan cause I was so used to snow.
                        </TextBody>
                        <ImageV path={folderPath} name={77}>
                            The snowman we built. We were unable to place the other snowball on top
                        </ImageV>
                        <TextBody>
                            After lunch, we considered going back to the bath, but it was closed. Instead, we found another spa that was open. So after a bit of grocery shopping, the spa became the plan! I remember they sell toothpaste-shaped caviar in the store, which was quite popular. That was a no for me.
                        </TextBody>
                        <ImageV path={folderPath} name={78}>
                            Spa day
                        </ImageV>
                        <TextBody>
                            When we left the spa, the storm came back. We stumbled through the blizzard, barely able to see anything.
                        </TextBody>
                        <TextBody>
                            Under that kind of condition, we were pretty worried that we would be stuck for another day. Jon reassured us that we'd hit the road no matter what the second day. The road would surely be plowed cause supplies needed to get through to those towns.
                        </TextBody>
                        <TextTitle ref={refs.current[7]}>
                            {titles[7]}
                        </TextTitle>
                        <TextBody>
                            We were supposed to stay in Bifröst that night as planned, but if you take a look at the map, you'll see that Egilsstaðir is on the far east side of Iceland, while Bifröst is on the west. It was a very long drive, so we had to cancel the plans for the day to make up for the travel time. One of the things I had been really looking forward to was whale watching on that day, but sadly that was not on the list anymore.
                        </TextBody>
                        <TextBody>
                            Another important thing was that there were two types of tourists in our group: those who signed up for the 6-day trip and those who signed up for the 8-day trip. That day, December 21, was the last day for the 6-day group, and they were supposed to return to Reykjavík for the night. Kathryn was especially worried about the changing weather, fearing she wouldn’t make it back home for Christmas, so she bought a flight ticket from Egilsstaðir to Reykjavík in the morning and left. The plan for the day was for Jon to drive all of us to Bifröst, where the agency would arrange cars to pick up the 6-day group and take them back to Reykjavík.
                        </TextBody>
                        <TextBody>
                            We left Egilsstaðir pretty early, and it was still heavily snowing at that time. All you could see while driving was white—the white storm surrounding us.
                        </TextBody>
                        <Image path={folderPath} name={79}>
                            Dim light earlier that day
                        </Image>
                        <TextBody>
                            Our first stop was a small restaurant by the road called Beitarhúsið. By that time, we had finally made it out of the storm.
                        </TextBody>
                        <ImageSmall path={folderPath} name={80}>
                            At the entrance of Beitarhúsið
                        </ImageSmall>
                        <TextBody>
                            Everyone was taking pictures like crazy when we got out of the bus. We had been in a storm for so long, now we could finally see the sky and the sun again.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[81, 82, 83]} ratio={[4, 4, 4]}>
                            View around Beitarhúsið
                        </ImageGrid>
                        <TextBody>
                            We met other travelers who had also been caught up in the storm and were back on the road again that day.
                        </TextBody>
                        <TextBody>
                            You think things would go smoothly from here on? Not really. Not long after we left the restaurant, we felt a sudden shake in the car. Jon opened his window for some fresh air, and that was when the couple sitting at the very front of the bus smelled smoke. We stopped to check, one of our tires was almost completely worn out.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[84, 85]} ratio={[6, 6]}>
                            Our poor tire
                        </ImageGrid>
                        <TextBody>
                            The guys in our group started helping change to the spare tire. Every car passing by was so kind to check if we needed help. Two local Icelandic men jumped out of their cars without saying a word and grabbed their tools to help us. They weren't even wearing jackets or gloves, just lying on the freezing ground helping us out. You should know, with all my gear on, I still couldn't stand outside for that long.
                        </TextBody>
                        <TextBody>
                            Jon said he saw a warning flash on the dashboard, but it disappeared quickly, and nothing else happened before the tire seemingly locked up. He didn't know the reason either. He mentioned that of all the tours he had taken, he had never run into such issues before. Jon asked us if we had checked the "extreme experience" box when we signed up for the trip.
                        </TextBody>
                        <TextBody>
                            So is this the end of the story? Nope. Shortly after we got the spare tire on and hit the road again, Jon suddenly stopped the car and told us, "It happened again." Our tire had locked itself once more and was worn out. With no spare tire left, we had no choice but to sit in the car and wait for the company to send us new tires.
                        </TextBody>
                        <TextBody>
                            I can’t remember how long that took, probably over an hour, but eventually we were back on the road again.
                        </TextBody>
                        <TextBody>
                            Later Jon told us that one of our glacier hiking guides had been hit by a car while he was helping others on the road. He was in the hospital but thankfully he would be fine.
                        </TextBody>
                        <TextBody>
                            Jon still made a quick stop at Goðafoss Waterfall so that we didn’t miss out too much, despite everything that had happened.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[86, 87]} ratio={[8, 4]}>
                            Goðafoss Waterfall
                        </ImageGrid>
                        <TextBody>
                            Beautiful waterfall. I will never complain about a single thing if I can always see such beautiful waterfalls.
                        </TextBody>
                        <TextBody>
                            We arrived in Akureyri and stopped briefly for dinner. It's such a beautiful city, and also a quite large one. We really wished we could have spent the night here. Ryan and I rushed to a camera store just 5 minutes before it closed so he could buy a single-use film camera.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[88, 89, 90]} ratio={[4, 3, 5]}>
                            Heart-shaped traffic light, the church and the best salmon I had since being in Iceland in Akureyri
                        </ImageGrid>
                        <TextBody>
                            About half an hour after we left Akureyri, Jon suddenly stopped the car. We weren’t supposed to take a break so soon, and he assured us there was nothing wrong with the vehicle. He just kept saying, “I think you need a break.” As we stepped out, we saw the northern lights stretching across the sky behind us. He said "Voilà!", happy as a little kid. It was the first decent northern lights we’d seen on this trip. Jon said it was the last night for some people on this tour, but we hadn't seen the northern lights yet, so he was paying attention for us the whole time while driving.
                        </TextBody>
                        <ImageSmall path={folderPath} name={91}>
                            First decent northern light
                        </ImageSmall>
                        <TextBody>
                            Jon was not obliged to make this stop for us. Thank you, Jon.
                        </TextBody>
                        <TextBody>
                            We hugged goodbye to the friends we made in this group when we finally arrived in Bifröst, including Grace. Then, Ryan and I put down our stuff and quickly rushed outside for the northern lights.
                        </TextBody>
                        <TextBody>
                            It wasn't better than the one we saw on the way, but we did see stunning stars. As it got later, the northern lights started to fade, so we just went back to the hotel.
                        </TextBody>
                        <ImageV path={folderPath} name={92}>
                            Stars that night. This is a poor camera, what I saw with my bare eyes were way more beautiful
                        </ImageV>
                        <TextTitle ref={refs.current[8]}>
                            {titles[8]}
                        </TextTitle>
                        <TextBody>
                            Ok here comes my favorite day and the most unexpected part of the trip. We were spending the whole day at Snaefellsnes Peninsula.
                        </TextBody>
                        <TextBody>
                            The day started with Kirkjufell Mountain, one of the filming locations for Game of Thrones.
                        </TextBody>
                        <Image path={folderPath} name={93}>
                            Kirkjufell Mountain
                        </Image>
                        <TextBody>
                            We then stopped at Ólafsvík, the only place where we could buy food for the day.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[94, 95]} ratio={[6, 6]}>
                            Sunrise and a non-frozen shower head by the sea
                        </ImageGrid>
                        <TextBody>
                            Next stop, Saxhóll Crater. A few stairs to climb to the top for an overlook view, but it wasn't too impressive.
                        </TextBody>
                        <TextBody>
                            Here's the good part. Started from Djúpalónssandur Beach, Jon made sure we had enough time to enjoy ourselves at each spot. Compared to just making a stop, taking a picture, and leaving, this felt so much better. We could now run along the cliff by the sea without worrying about needing to leave shortly.
                        </TextBody>
                        <Image path={folderPath} name={96}>
                            Snæfellsjökull
                        </Image>
                        <ImageGrid path={folderPath} name={[99, 97, 98, 100]} ratio={[2, 4, 4, 2]}>
                            Djúpalónssandur Beach
                        </ImageGrid>
                        <TextBody>
                            Saw this at an information center. There were no introductions or anything about it. I asked the guy there what it was for, and he said it was for taking baths, just go down the stairs which lead outside. I asked him if it was for humans. He replied, "No, we are not that sick." It was for sheep.
                        </TextBody>
                        <ImageSmall path={folderPath} name={101}>
                            The path
                        </ImageSmall>
                        <ImageGrid path={folderPath} name={[102, 103, 104]} ratio={[3, 6, 3]}>
                            Lóndrangar
                        </ImageGrid>
                        <TextBody>
                            I didn't take pictures with my phone for this one, so let me just describe it to you. At sunset, Ryan and I were playing on the seesaw on the cliff by the sea. The weather was so beautiful that day.
                        </TextBody>
                        <ImageV path={folderPath} name={105}>
                            Gatklettur, Arnarstapi
                        </ImageV>
                        <ImageGrid path={folderPath} name={[106, 107]} ratio={[8, 4]}>
                            Stone Bridge, Arnarstapi
                        </ImageGrid>
                        <ImageGrid path={folderPath} name={[108, 109]} ratio={[4, 8]}>
                            Arnarstapi
                        </ImageGrid>
                        <TextBody>
                            Crosses with flashing colored lights were so cool.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[110, 111, 112]} ratio={[5, 3, 4]}>
                            Búðakirkja
                        </ImageGrid>
                        <TextBody>
                            The day in Snaefellsnes Peninsula ended with seal watching at Ytri Tunga. They only stuck their heads out of the water because it was too cold.
                        </TextBody>
                        <Image path={folderPath} name={113}>
                            Ytri Tunga
                        </Image>
                        <TextBody>
                            We went back to the hotel. Others in our group told us a good spot to see the northern lights, up on a small hill. The path wasn’t marked on the map, so we had to follow the footprints of those who had gone before us. Ryan and I made our way there. It was a nice place, but the wind on the hill was very strong. It was freezing cold and we watched the northern lights fade. Feeling discouraged and extremely tired, we decided to head back to the hotel. After sitting in the lobby for a while, I still felt too tired to go back out, so I just went to sleep. It was about 11:30 pm by then.
                        </TextBody>
                        <TextTitle ref={refs.current[9]}>
                            {titles[9]}
                        </TextTitle>
                        <TextBody>
                            Miraculously woke up at 6:30 am and couldn't sleep. After a week, I was still jetlagged. I saw 6 missed calls from Ryan on my phone. Checking his Instagram, I saw the huge northern lights he posted from last night: blue and green ribbons dancing in the sky. He didn’t have phone service, so he walked half an hour from our northern lights viewing spot back to the hotel at 1 am to call me, then went back out.
                        </TextBody>
                        <TextBody>
                            I looked out of my room window, which faced north, and saw some white stripes in the sky. That looked promising. After I got dressed, the white stripes disappeared. Even more promising. I headed out to our spot to see if I had any luck with the northern lights.
                        </TextBody>
                        <Image path={folderPath} name={114}>
                            Northern lights that morning
                        </Image>
                        <TextBody>
                            Over half of the sky was covered with green dancing northern lights. My GoPro was set to take a photo every 30 seconds for a time-lapse, so the northern lights in the pictures turned out a bit blurred. I told my friend I couldn't capture how beautiful the moment was. He said, 'Throw away the camera. So many people have taken great photos and videos of the northern lights. You don't need to do that, just enjoy.'
                        </TextBody>
                        <TextBody>
                            I was lying on the snow, tears streaming down my face. Was I dreaming?
                        </TextBody>
                        <Image path={folderPath} name={115}>
                            Northern lights that morning with a shooting star
                        </Image>
                        <Image path={folderPath} name={116}>
                            Northern lights that morning with shooting stars
                        </Image>
                        <TextBody>
                            I saw shooting stars and made wishes. I also captured a lot of them with my camera, though I didn't realize it until I checked the photos.
                        </TextBody>
                        <TextBody>
                            There was a beam of northern light stretching across the sky, following me as I walked back to the hotel.
                        </TextBody>
                        <TextBody>
                            I called Ryan several times, but he was still asleep. When he woke up, we exchanged stories. He said I should've banged on his door. I told him, 'Well, you could’ve banged on mine.' We each had our own moments with the northern lights, and I guess that was good enough and was meant to be.
                        </TextBody>
                        <TextBody>
                            Now, whenever we look at any northern lights postcards in the store, we know that none of them could ever compare to the ones we saw.
                        </TextBody>
                        <TextBody>
                            The day started off strong, and we still had exciting plans ahead: time for the lava cave tour! Everyone in our group had signed up for this one.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[117, 118, 119]} ratio={[6, 3, 3]}>
                            Inside the lava cave
                        </ImageGrid>
                        <TextBody>
                            All the places we were standing on were once flowing lava rivers. My geography knowledge is so poor that I can barely remember the things our guide introduced. I think our guide looked a bit like James McAvoy. He's from Austria.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[120, 121]} ratio={[7, 5]}>
                            Lava samples that I can't remember how they were formed
                        </ImageGrid>
                        <TextBody>
                            I asked him how he ended up in Iceland and became a tour guide. He said he was really interested in volcanoes and was studying related subjects. He loved the lifestyle of Icelandic people: they live in the moment. According to him, Icelanders don’t know if they’ll see tomorrow's sunrise(I guess he said this because of the volcanoes in Iceland), so they live each day like it’s their last. They can’t understand the rigid style of Germans and wonder what its point is. If you ask an Icelander to hang out next week, he’d hesitate. He doesn’t know what will happen by then, he doesn’t know if he can make it. They love it when you ask, 'Let’s have dinner tonight.'
                        </TextBody>
                        <TextBody>
                            My favorite moment during that tour was when we went deeper underground, where no sunlight could reach. Our guide turned off all the lights so that we were left in a moment of pure darkness. No kids were screaming, everyone was quietly enjoying it.
                        </TextBody>
                        <TextBody>
                            There's an exclusive tour once every year, going deep into the lava caves. Where we stopped is the entrance to that tour. You need to sign up by emailing them and be ready at any moment. They’ll let you know when it's time to go. I would really love to take that trip: imagine having that kind of spare time and that state of mind to just go.
                        </TextBody>
                        <TextBody>
                            I always wanted a piece of obsidian. He told me there was a lava show in downtown Reykjavík, where they pour a fresh bucket of lava, cool it down, smash it, and give you a piece. Sadly, I couldn’t make it to that show.
                        </TextBody>
                        <TextBody>
                            Barnafoss was next. It was drizzling at that time, but it didn't affect the beautiful scene at all.
                        </TextBody>
                        <ImageV path={folderPath} name={122}>
                            Barnafoss
                        </ImageV>
                        <ImageGrid path={folderPath} name={[123, 124, 125]} ratio={[3, 5, 4]}>
                            More about Barnafoss
                        </ImageGrid>
                        <TextBody>
                            And here came the last stop of our tour, Deildartunguhver. Look at these beautiful mosses growing right next to the boiling hot spring.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[126, 127]} ratio={[8, 4]}>
                            Deildartunguhver
                        </ImageGrid>
                        <TextBody>
                            Jon dropped Ryan and me off at the same place in Reykjavík. I was leaving for Norway the next day, while he was staying in Reykjavík until after Christmas. It was December 23rd. We hung out in downtown together that night.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[128, 129, 130]} ratio={[5, 2, 5]}>
                            Our night
                        </ImageGrid>
                        <TextBody>
                            We were sitting on a bench at a small Christmas market, drinking hot red wine and writing postcards to our friends by the dim candlelight. I said that I didn't know what to write on the cards. He admitted he didn't either and said he was just scribbling down random stuff. In his opinion, the purpose of sending postcards isn’t about what you write but about seeing that friend again in the near future. Otherwise, what’s the point of writing one in the first place. But even though he said that, I oversaw him writing long paragraphs for each of his friends.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[131, 132]} ratio={[6, 6]}>
                            At Christmas market writing postcards
                        </ImageGrid>
                        <TextBody>
                            I forgot to take pictures of the postcards I sent. Out of the at least 6 postcards I mailed, only 2 were actually received including the one I sent to myself.
                        </TextBody>
                        <ImageSmall path={folderPath} name={133}>
                            Sending the postcards
                        </ImageSmall>
                        <TextBody>
                            We ran into this creepy-looking market, which I absolutely wouldn't have dared to go inside if I weren't with Ryan.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[134, 135, 136, 137]} ratio={[3, 3, 3, 3]}>
                            Inside the creepy market
                        </ImageGrid>
                        <TextBody>
                            And also a dead studio. They have some pieces I really love.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[138, 139, 140]} ratio={[4, 4, 4]}>
                            Dead studio
                        </ImageGrid>
                        <TextBody>
                            That Icelander said they were not psycho, but then I found out they eat puffins.
                        </TextBody>
                        <ImageSmall path={folderPath} name={141}>
                            Menu from a restaurant
                        </ImageSmall>
                        <TextBody>
                            The day ended with this parade on the street. People were holding candles, singing, marching forward.
                        </TextBody>
                        <Image path={folderPath} name={142}>
                            Christmas parade I presume
                        </Image>
                        <TextBody>
                            So my trip to Iceland ended here.
                        </TextBody>
                        <TextTitle ref={refs.current[10]}>
                            {titles[10]}
                        </TextTitle>
                        <TextBody>
                            Ryan went snorkeling in a dry suit. According to him, it was freezing, he couldn't feel his limbs, but it was so worth it.
                        </TextBody>
                        <TextBody>
                            Kathryn spent one more day in Reykjavík so she didn’t miss out on the northern lights. She made it back home to celebrate Christmas with family. Earlier this year, there was a huge northern lights happening. She saw colorful dancing lights almost right in front of her house. She texted me saying: I was thinking about you, about how you were chasing the northern lights back then when I was looking up at this sky.
                        </TextBody>
                        <TextBody>
                            I don’t really remember what happened to Grace afterward. I do recall her posting wonderful pictures and sharing her adventures as she continued traveling through Europe.
                        </TextBody>
                        <TextBody>
                            As for me, as I mentioned earlier, I was heading to Norway next. The next morning when I was sitting at the airport, having breakfast and waiting to board, I looked back at the past week and everything felt so surreal. My original motivation for going to Iceland was to dive in Silfra. To do that, I need to get several diving certificates, and the only thing standing between me and that dream now is the dry suit diving certificate. Next time I come to Iceland, I will visit during summer. I will drive around the whole island, go hiking in the highlands, see whales and puffins, and I will try my luck on erupting volcanoes.
                        </TextBody>
                        <Image path={folderPath} name={143}>
                            Roughly the places I went in Iceland
                        </Image>
                        <TextBody>
                            Thank you Iceland, I miss you.
                        </TextBody>
                    </Box>
                    <MenuButton open={openDrawer(true)}></MenuButton>
                    <Drawer open={open} onClose={openDrawer(false)}>
                        {<MenuDrawer isMobile={isMobile} func={openDrawer(false)} titles={titles} refs={refs}/>}
                    </Drawer>
                </Box>

            }
        </Box>
    )
};

export default Iceland;
