import {Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import React from "react";
import Drawer from "@mui/material/Drawer";

import TextBody from "./modules/textBody";
import TextTitle from "./modules/textTitle";
import Image from "./modules/image";
import Music from "./modules/music";
import ImageV from "./modules/imageV";
import ImageSmall from "./modules/imageSmall";
import ImageGrid from "./modules/imageGrid";
import PreviewCard from "./modules/previewCard";
import MenuButton from "./modules/menuButton";
import MenuDrawer from "./modules/menuDrawer";

const TODO = ({ isPreview }) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = (value) => () => {
        setOpen(value);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const titles=[
        "TODO"
    ];
    const refs = React.useRef(Array.from({ length: titles.length }, () => React.createRef()));

    const folderPath="TODO";

    return (
        <Box>
            {isPreview &&
                <PreviewCard path={folderPath}
                             title={"TODO"}
                             date={"TODO"}
                             isMobile={isMobile}>
                    TODO
                </PreviewCard>
            }
            {!isPreview &&
                <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
                    <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>TODO</Typography>
                    <Typography color="textSecondary" className='blogDate' gutterBottom>
                        TODO
                    </Typography>
                    <Box className='bolgContent'>
                        <Music src={"TODO"}
                               title={"TODO"}/>
                        <TextBody></TextBody>
                        <TextTitle></TextTitle>
                        <Image></Image>
                        <ImageV></ImageV>
                        <ImageSmall></ImageSmall>
                        <ImageGrid></ImageGrid>
                    </Box>
                    <MenuButton open={openDrawer(true)}></MenuButton>
                    <Drawer open={open} onClose={openDrawer(false)}>
                        {<MenuDrawer isMobile={isMobile} func={openDrawer(false)} titles={titles} refs={refs}/>}
                    </Drawer>
                </Box>
            }
        </Box>
    )
};

export default TODO;
