import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import React from "react";

const PreviewCard = ({ children, title, date, path, isMobile }) => {
    return (
        <Box className="previewCard">
            <Grid container style={{height: '100%'}}>
                <Grid item xs={4}>
                    <Box
                        component="img"
                        className='previewImage'
                        src={`${process.env.PUBLIC_URL}/blog/${path}/0.jpg`}
                        loading="lazy"
                    />
                </Grid>
                <Grid item xs={8} sx={{padding: 2}} className='previewText'>
                    <Typography variant={isMobile ? "h6" : "h5"} className='previewTitle'>{title}</Typography>
                    <Typography variant="body2" className='previewDate' color="textSecondary">
                        {date}
                    </Typography>
                    <Typography variant="body1" className='previewContent'>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PreviewCard;
