import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Photos from './pages/photos';
import Blogs from './pages/blogs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import React from 'react';
import {IconButton, useMediaQuery} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BlogPost from './pages/blogPost';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Verdana',
            'Geneva',
            'sans-serif',
        ].join(','),
        // '@media (max-width: 768px)': {
        //     fontSize: '0.5rem',
        // },
    },
});

function CustomBreadcrumbs() {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="·" className="breadcrumbs">
            <Link underline="none" color="dimgrey" href="/" className="breadcrumbs-tab"
                  sx={{ fontWeight: currentPath==='/'?'bold':'normal' }}>
                Home
            </Link>
            <Link underline="none" color="dimgrey" href="/photos" className="breadcrumbs-tab"
                  sx={{ fontWeight: currentPath==='/photos'?'bold':'normal' }}>
                Photos
            </Link>
            <Link underline="none" color="dimgrey" href="/blogs" className="breadcrumbs-tab"
                  sx={{ fontWeight: currentPath.slice(0,6)==='/blogs'?'bold':'normal' }}>
                Blogs
            </Link>
            <Link underline="none" color="dimgrey" href="/about" className="breadcrumbs-tab"
                  sx={{ fontWeight: currentPath==='/about'?'bold':'normal' }}>
                About me
            </Link>
        </Breadcrumbs>
    )
}

function App() {
    const [locations, setLocations] = React.useState(new Map());
    const [filmTypes, setFilmTypes] = React.useState(new Map());
    const [times, setTimes] = React.useState(new Map());
    const [loading, setLoading] = React.useState(true);
    const [homePhotos, setHomePhotos] = React.useState({});

    const isMobile = useMediaQuery('(max-width:768px)');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const parseDate = (dateString) => {
        const [month, year] = dateString.split(' ');
        if (year){
            return new Date(`${month} 1, ${year}`);
        }else{
            return new Date(`January 1,${month}`);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const photoResponse = await fetch('photo_index.json');
                const photoData = await photoResponse.json();

                const sortedPhotoData = Object.fromEntries(
                    Object.entries(photoData).sort(([, a], [, b]) => {
                        return parseDate(b.time) - parseDate(a.time);
                    })
                );

                let locationMap = new Map();
                let filmTypeMap = new Map();
                let timeMap = new Map();

                for (let [photo, value] of Object.entries(sortedPhotoData)) {
                    let tmpLocation = value.location;
                    let tmpFilmType = value.filmType;
                    let tmpTime = value.time;

                    if (!locationMap.get(tmpLocation)) {
                        locationMap.set(tmpLocation, []);
                    }
                    locationMap.get(tmpLocation).push(photo);

                    if (!filmTypeMap.get(tmpFilmType)) {
                        filmTypeMap.set(tmpFilmType, []);
                    }
                    filmTypeMap.get(tmpFilmType).push(photo);

                    if (!timeMap.get(tmpTime)) {
                        timeMap.set(tmpTime, []);
                    }
                    timeMap.get(tmpTime).push(photo);
                }

                locationMap = new Map(
                    [...locationMap.entries()].sort((a, b) => a[0].localeCompare(b[0]))
                );
                filmTypeMap = new Map(
                    [...filmTypeMap.entries()].sort((a, b) => a[0].localeCompare(b[0]))
                );
                timeMap = new Map(
                    [...timeMap.entries()].sort((a, b) => parseDate(b[0]) - parseDate(a[0]))
                );

                setLocations(locationMap);
                setFilmTypes(filmTypeMap);
                setTimes(timeMap);

                const homeResponse = await fetch('home_index.json');
                const homeData = await homeResponse.json();

                setHomePhotos(homeData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching the JSON files:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading){
        return;
    }

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <header style={{display: 'flex', alignItems: 'center'}}>
                    <Box component="img"
                         src={`${process.env.PUBLIC_URL}/xuan.png`}
                         loading="lazy"
                         sx={{width: isMobile? 45:70, paddingLeft: isMobile? '10px':'20px'}}
                    />
                    <Box className="breadcrumbs-container">
                        <CustomBreadcrumbs/>
                    </Box>
                </header>
                <Box style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                    <Routes>
                        <Route path="/" element={<Home photos={homePhotos}/>} />
                        <Route path="/about" element={<About />} />
                        <Route path="/photos" element={<Photos locationMap={locations} filmTypeMap={filmTypes} timeMap={times}/>} />
                        <Route path="/blogs" element={<Blogs />} />
                        <Route path="/blogs/:blogId" element={<BlogPost />} />
                    </Routes>
                </Box>
                <IconButton
                    onClick={scrollToTop}
                    variant="contained"
                    sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000,
                        backgroundColor: 'rgba(255, 255, 255, 0.85)', "&:hover": {backgroundColor: 'rgba(229,229,229,0.85)'} }}
                    id="back-to-top-btn"
                >
                    <ArrowUpwardIcon sx={{fontSize: 30}}/>
                </IconButton>
                <footer className="footer">
                    © 2024 Xuan Yu. All rights reserved.
                </footer>
            </ThemeProvider>
        </Router>
    );
}

export default App;
