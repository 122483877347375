import React from 'react';
import Box from '@mui/material/Box';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {Dialog, IconButton, useMediaQuery} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import CloseIcon from '@mui/icons-material/Close';
import {Typography} from "@mui/material";


function About() {
    const [imageOpen, setImageOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState("");

    const imageClick = (image) => {
        setImageOpen(true);
        setSelectedImage(image);
    };
    const imageCloseClick = (image) => {
        setImageOpen(false);
        // setSelectedImage("");
    };

    const isMobile = useMediaQuery('(max-width:768px)');
    const spacing = isMobile ? 1 : 2;

    const selfies = [
        '000367170022.jpg',
        '000568940003.jpg',
        // '000252310015.jpg',
        '000210350028.jpg',
        '000252310016.jpg',
        '000252270008.jpg',
        '000552600025.jpg',
        '000220180035.jpg',
        '000670390012.jpg',
    ];

    return (
        <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/selfies/main.jpg`}
                loading="lazy"
                sx={{width: '100%'}}>
            </Box>
            <Box sx={{paddingTop: 5, width: isMobile ? '90%' : '70%', margin: '0 auto'}}>
                <Typography variant="body1" className="paragraph">
                    Hi welcome, I'm Xuan! My day job has nothing to do with photography.
                    I just love taking pictures, trying to capture what I think is beautiful.
                    Though I've tried my best to avoid stepping into deep water of photography for so many reasons, here
                    I am anyways.
                    And I'm enjoying every moment of it.
                </Typography>
                <Typography variant="body1" className="paragraph">
                    I'm shooting with my Nikon FE and Mamiya 645 1000s, playing with color films, and I still have so
                    many things to figure out.
                    Using a film camera really makes you think about why you press the shutter button.
                    The moments feel more precious because of the effort you put in, the waiting time for development,
                    and the thrill of not knowing what you’ll get.
                    The feeling of unexpected is just fascinating. I've done my part, and I'll take whatever comes out.
                </Typography>
                <Typography variant="body1" className="paragraph">
                    Also, I do love people asking me if I'm shooting on a real camera.
                </Typography>
                <Typography variant="body1" className="paragraph">
                    Hope you have fun exploring my adventures!
                </Typography>
                <br/>
                <Typography variant="body2" className="paragraph">
                    Comment on Mar 25, 2025:<br/>
                    I once went to my favorite Chinese singer’s concert in Chicago. He said there are so many
                    limitations here—what songs you can and cannot sing. 'Come to Hong Kong next time,' he said, 'I can
                    sing whatever I like.' That’s exactly how I feel about social media. You have to fit your work into
                    its framework and format. It limits your creativity and imagination to that certain scale. It’s
                    also hard not to fall into the trap of seeking attention. Here, I can be myself and share my work my
                    way. Please enjoy!
                </Typography>
            </Box>
            <Box sx={{textAlign: 'center'}}>
                <IconButton component="a" href="https://www.instagram.com/iam_2524?igsh=cmo5aWIwNTA5NHF0&utm_source=qr"
                            target="_blank" rel="noopener noreferrer">
                    <InstagramIcon sx={{fontSize: 35}}/>
                </IconButton>
                <IconButton component="a" href="https://www.youtube.com/@Blabla-wq8dd" target="_blank"
                            rel="noopener noreferrer">
                    <YouTubeIcon sx={{fontSize: 35}}/>
                </IconButton>
            </Box>
            <Box sx={{paddingTop: 5}}>
                <Masonry columns={3} spacing={spacing}>
                    {selfies.map((image, index) => (
                        <Box
                            key={image}
                            component="img"
                            src={`${process.env.PUBLIC_URL}/selfies/${image}`}
                            loading="lazy"
                            className="smallImage"
                            onClick={() => imageClick(image)}
                        />
                    ))}
                </Masonry>
            </Box>
            <Dialog open={imageOpen} onClose={imageCloseClick} maxWidth={false}
                    sx={{width: '100%', backgroundColor: 'transparent'}}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        }
                    }}>
                <IconButton
                    color="lightgrey"
                    onClick={imageCloseClick}
                    aria-label="close"
                    sx={{position: 'fixed', right: 8, top: 8, "&:hover": {backgroundColor: 'rgba(229,229,229,0.35)'}}}
                >
                    <CloseIcon/>
                </IconButton>
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/selfies/${selectedImage}`}
                    sx={{
                        // height: '85vh',
                        width: 'auto',
                        maxHeight: '85vh',
                        objectFit: 'contain'
                    }}
                    loading="lazy"
                />
            </Dialog>
        </Box>
    );
}

export default About;
